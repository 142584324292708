export default {
    get(key: string): any {
        const rawData = localStorage.getItem(key) || null;
        if (!rawData) {
            return null;
        }

        try {
            const data = JSON.parse(rawData);
            return data;
        } catch (_) {
            return rawData;
        }
    },

    delete(key: string): any {
        localStorage.removeItem(key);
    },

    set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }
};
