
import AutoComplete from '@/components/AutoComplete.vue';
import firebase from 'firebase/app';
import get from 'lodash/get';
import storageService from '@/services/storage';
import useNavigation from '@/composables/useNavigation';
import { TrackJS } from 'trackjs';
import { addGuestRecord, notifyHost } from '@/services/checkin';
import { defineComponent, ref, computed, onMounted } from 'vue';
import { isEmail, isPhoneNumber } from '@/utils/fieldValidation';
import { loading, toast } from '@/utils/frameworkFeatures';
import { useMainStore } from '@/stores/main.store';
import { storeToRefs } from 'pinia';

export default defineComponent({
    name: 'CheckIn',
    components: {
        AutoComplete,
    },
    props: {
        headerTitle: String
    },
    setup(props, { emit }) {
        const { gotoConfirmation } = useNavigation();
        const isToastOpenRef = ref(false);
        const selectHost = ref(false);
        const store = useMainStore();
        const { guestModel } = storeToRefs(store);

        onMounted(() => {
            if (store.checkInSettings.requireHost && !store.checkInSettings.enableOptionalHost) {
                selectHost.value = true;
            }
        });

        const nextdisabled = computed(() => {
            return store.checkInSettings.enableOptionalHost
                ? selectHost.value === true && !guestModel.value.employee
                : !guestModel.value.employee;
        });

        const onAutocompleteSelection = (data: Employee) => {
            if (data) {
                store.setGuestHost(data);
            } else {
                store.clearGuestHost();
            }
        };

        const isEmailValid = computed(() => {
            return (guestModel.value.email && isEmail(guestModel.value.email)) ||
                    (!guestModel.value.email && !store.checkInSettings.requireEmail);
        });

        const isPhoneValid = computed(() => {
            return (guestModel.value.phoneNumber && isPhoneNumber(guestModel.value.phoneNumber)) || 
                    (!guestModel.value.phoneNumber && !store.checkInSettings.requirePhoneNumber);
        });

        const guestFormIsValid = computed(() => {
            return (
                guestModel.value &&
                guestModel.value.firstName &&
                guestModel.value.firstName &&
                guestModel.value.lastName &&
                isEmailValid.value &&
                isPhoneValid.value
            );
        });

        const cleanGuestModel = () => {
            store.$patch({ guestModel: {} });
        };

        const onGuestCheckIn = async () => {
            const loadingInstance = await loading({ message: 'Please wait...' });
            let payload, notifyData;

            const host = selectHost.value && guestModel.value.employee && 
            {
                firstName: guestModel.value.employee.firstName,
                lastName: guestModel.value.employee.lastName,
                email: guestModel.value.employee.email,
                userId: guestModel.value.employee.id,
                phoneNumber: guestModel.value.employee.phoneNumber,
                smsOptOut: guestModel.value.employee.smsOptOut || false,
            };

            try {
                loadingInstance.present();

                const guestRecord = {
                    email: get(guestModel, 'value.email', ''),
                    firstName: guestModel.value.firstName,
                    lastName: guestModel.value.lastName,
                    phoneNumber: get(guestModel, 'value.phoneNumber', ''),
                    host: host || null,
                    customerId: store.customerId,
                    checkInMethod: 'mobile',
                    userType: 'guest',
                    locationId: store.tempLocationId || '',
                };

                notifyData = host && {
                    customerId: store.customerId,
                    email: get(guestModel, 'value.employee.email'),
                    hostName: `${guestModel.value.firstName} ${guestModel.value.lastName}`,
                    phoneNumber: get(guestModel, 'value.employee.phoneNumber', ''),
                    smsOptOut: guestModel.value.employee.smsOptOut || false,
                    hostUserId: guestModel.value.employee.id,
                };

                payload = {
                    ...guestRecord,
                    ...{
                        checkedInAt: firebase.firestore.Timestamp.now(),
                        checkedOutAt: '',
                    },
                };
                
                storageService.set('visitorData', {
                    email: guestRecord.email,
                    firstName: guestRecord.firstName,
                    lastName: guestRecord.lastName,
                    phoneNumber: guestRecord.phoneNumber,
                });

                if (host) {
                    await notifyHost(notifyData);
                }
                await addGuestRecord(store.customerId, payload);

                isToastOpenRef.value = true;
                
                gotoConfirmation('check-in', 1);
                emit('on-hide-selection');
            } catch (err: any) {
                console.error(err.message);
                toast({ message: 'An error occurred and we were unable to check in' });
                TrackJS.console.error(`Failed to checkin at adding guest record (${err.message}): ${JSON.stringify({
                    payload,
                    notifyData
                })}`, 'onGuestCheckIn');
            } finally {
                loadingInstance && loadingInstance.dismiss();
            }
        };

        const resetForm = () => {
            emit('on-reset');
        };

        const goToHome = () => {
            cleanGuestModel();

            resetForm();
        };

        return {
            store,
            goToHome,
            guestFormIsValid,
            guestModel,
            isToastOpenRef,
            nextdisabled,
            onAutocompleteSelection,
            onGuestCheckIn,
            selectHost,
        };
    },
});
